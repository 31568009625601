import React, { Component } from "react";
import ProjectDetailsModal from "./ProjectDetailsModal";
import dlamos1 from "../images/dlamos1.jpg";
import dlamos2 from "../images/dlamos2.jpg";
import dlamos3 from "../images/dlamos3.jpg";

class Projects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            deps: {},
            detailsModalShow: false,
        };
    }

    render() {
        let detailsModalShow = (data) => {
            this.setState({ detailsModalShow: true, deps: data });
        };

        let detailsModalClose = () =>
            this.setState({ detailsModalShow: false });
        if (this.props.resumeProjects && this.props.resumeBasicInfo) {
            console.log(process.env.PUBLIC_URL);
            var sectionName = this.props.resumeBasicInfo.section_name.projects;
            var projects = this.props.resumeProjects.map(function (projects) {
                return (
                    <div
                        className="col-sm-12 col-md-6 col-lg-4"
                        key={projects.title}
                        style={{ cursor: "pointer" }}
                    >
                        <span className="portfolio-item d-block">
                            <div
                                className="foto"
                                onClick={() => detailsModalShow(projects)}
                            >
                                <div>
                                    <img
                                        className="project-image-preview"
                                        src={
                                            process.env.PUBLIC_URL +
                                            projects.images[0]
                                        }
                                        alt="projectImages"
                                        // height="230"
                                        // style={{
                                        //     marginBottom: 0,
                                        //     paddingBottom: 0,
                                        //     position: "relative",
                                        // }}
                                    />
                                    <span className="project-date">
                                        {projects.startDate}
                                    </span>
                                    <br />
                                    <p className="project-title-settings mt-3">
                                        {projects.title}
                                    </p>
                                </div>
                            </div>
                        </span>
                    </div>
                );
            });
        }

        return (
            <section id="portfolio">
                <div className="col-md-12">
                    <h1 className="section-title" style={{ color: "black" }}>
                        <span>{sectionName}</span>
                    </h1>
                    <div className="col-md-12 mx-auto">
                        <div className="row mx-auto">{projects}</div>
                    </div>
                    <ProjectDetailsModal
                        show={this.state.detailsModalShow}
                        onHide={detailsModalClose}
                        data={this.state.deps}
                    />
                </div>
            </section>
        );
    }
}

function getImage(path) {
    try {
        return require(`${path}`);
    } catch (err) {
        console.error(`Failed to load image: ${path}`, err);
        return null;
    }
}

export default Projects;
