import React, { Component } from "react";

class TreeCanvas extends Component {
    constructor(props) {
        super(props);
        this.canvasRef = React.createRef();
    }

    random(min, max) {
        return Math.floor(Math.random() * (max - min + 1) + min);
    }

    generateTree() {
        const canvas = this.canvasRef.current;
        const ctx = canvas.getContext("2d");
        const trunkWidth = this.random(5, 10);
        const trunkHeight = this.random(100, canvas.height);
        const numLeaves = this.random(100, 200);

        const trunkX = (canvas.width - trunkWidth) / 2;
        const trunkY = canvas.height - trunkHeight;

        // Draw trunk
        ctx.fillStyle = this.props.trunkColor || "brown"; // If no prop is provided, default to brown
        ctx.fillRect(trunkX, trunkY, trunkWidth, trunkHeight);

        // Draw leaves
        const leafThickness = this.random(1, 7);
        ctx.fillStyle = this.props.leavesColor || "green"; // If no prop is provided, default to green
        for (let i = 0; i < numLeaves; i++) {
            const h = this.random(0, trunkHeight);
            const leafWidth = 0.5 * this.random(0, h);
            const leafX = (canvas.width - leafWidth) / 2;
            const leafY = trunkY + h;

            // ctx.fillStyle = "green";
            ctx.fillRect(leafX, leafY - 10, leafWidth, leafThickness);
        }
    }

    componentDidMount() {
        this.generateTree();
    }
    componentDidUpdate(prevProps) {
        if (prevProps.height !== this.props.height) {
            this.generateTree();
        }
    }

    render() {
        return (
            <canvas
                ref={this.canvasRef}
                width={400}
                height={this.props.height}
                style={{ display: "block" }}
            ></canvas>
        );
    }
}

export default TreeCanvas;
