import React, { Component } from "react";
import Typical from "react-typical";
import Switch from "react-switch";
import BlinkingText from "./BlinkingText.js";
import TreeCanvas from "./TreeComponent.js";
import { NavItem } from "react-bootstrap";

class Header extends Component {
    titles = [];

    constructor() {
        super();
        this.state = {
            checked: false,
            leavesColor: "green",
            trunkColor: "brown",
        };
        this.onThemeSwitchChange = this.onThemeSwitchChange.bind(this);
    }

    onThemeSwitchChange(checked) {
        this.setState({ checked });
        this.setTheme();
    }

    setTheme() {
        var dataThemeAttribute = "data-theme";
        var body = document.body;
        var newTheme =
            body.getAttribute(dataThemeAttribute) === "dark" ? "light" : "dark";
        body.setAttribute(dataThemeAttribute, newTheme);
        // Set trunk and leaves colors based on the theme.
        if (newTheme === "dark") {
            this.setState({
                trunkColor: "#282828",
                leavesColor: "#282828",
            });
        } else {
            this.setState({
                trunkColor: "brown",
                leavesColor: "green",
            });
        }
    }

    generateRandomTrees() {
        const numOfTrees = Math.floor(Math.random() * 16) + 10;
        const trees = [];

        for (let i = 0; i < numOfTrees; i++) {
            const randomX =
                Math.random() * document.documentElement.clientWidth;
            console.log(randomX);
            // Convert x-coordinate to a percentage of the screen's width
            const xPercentage =
                (randomX / document.documentElement.clientWidth) * 100;
            console.log(xPercentage);

            // Calculate height based on the formula
            const heightPercentage = 0.1 * Math.pow(xPercentage - 50, 2);

            // Convert height percentage back to pixels
            const treeHeight =
                (heightPercentage / 100) * window.innerHeight + 100;

            console.log(
                "X:",
                randomX,
                "X%:",
                xPercentage,
                "Height%:",
                heightPercentage
            );

            const treeWidth = 400;

            trees.push(
                <div
                    key={i}
                    style={{
                        position: "absolute",
                        bottom: 0,
                        left: randomX - treeWidth / 2,
                        height: treeHeight,
                    }}
                    className="tree"
                >
                    <TreeCanvas
                        height={treeHeight}
                        trunkColor={this.state.trunkColor}
                        leavesColor={this.state.leavesColor}
                    />
                </div>
            );
        }
        return trees;
    }

    render() {
        if (this.props.sharedData) {
            var name = this.props.sharedData.name;
            this.titles = this.props.sharedData.titles
                .map((x) => [x.toUpperCase(), 1500])
                .flat();

            var nameText = this.state.checked ? (
                <div className="blinking-text">
                    <BlinkingText text={name} />
                </div>
            ) : (
                <h1 className="mb-0" style={{ fontSize: "64px" }}>
                    <Typical steps={[name]} wrapper="p" />
                </h1>
            );
        }

        const HeaderTitleTypeAnimation = React.memo(
            () => {
                return (
                    <Typical
                        className="title-styles"
                        steps={this.titles}
                        loop={50}
                    />
                );
            },
            (props, prevProp) => true
        );

        // const nameText = {};

        return (
            <header
                id="home"
                style={{ height: window.innerHeight - 140, display: "block" }}
            >
                <div className="trees">{this.generateRandomTrees()}</div>
                <div className="row aligner" style={{ height: "100%" }}>
                    <div className="col-md-12">
                        <div>
                            <div className="name-container">{nameText}</div>

                            <div className="title-container">
                                <HeaderTitleTypeAnimation />
                            </div>
                            <Switch
                                checked={this.state.checked}
                                onChange={this.onThemeSwitchChange}
                                offColor="#baaa80"
                                onColor="#353535"
                                className="react-switch mx-auto"
                                width={90}
                                height={40}
                                uncheckedIcon={
                                    <span
                                        className="iconify"
                                        // data-icon="twemoji:moon"
                                        data-inline="false"
                                        style={{
                                            display: "block",
                                            height: "100%",
                                            fontSize: 25,
                                            textAlign: "end",
                                            marginLeft: "20px",
                                            color: "#353239",
                                        }}
                                    ></span>
                                }
                                checkedIcon={
                                    <span
                                        className="iconify"
                                        // data-icon="noto-v1:sun-with-face"
                                        data-inline="false"
                                        style={{
                                            display: "block",
                                            height: "100%",
                                            fontSize: 25,
                                            textAlign: "end",
                                            marginLeft: "10px",
                                            color: "#353239",
                                        }}
                                    ></span>
                                }
                                id="icon-switch"
                            />
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

export default Header;
