import React, { Component } from "react";

class Contact extends Component {
    render() {
        if (this.props.sharedBasicInfo) {
            var email = this.props.sharedBasicInfo.email;
        }
        return (
            <section id="contact" className="pb-5">
                <div className="col-md-12 mx-auto">
                    <div className="col-md-12">
                        <h1
                            className="section-title"
                            style={{ color: "black" }}
                        >
                            <span
                                className="text-white"
                                style={{ textAlign: "center" }}
                            >
                                Contact
                            </span>
                        </h1>
                    </div>
                </div>

                <div className="email-container">
                    <span>
                        <strong>EMAIL</strong>
                    </span>
                    <a
                        href="mailto:awiteck@princeton.edu"
                        className="contact-email"
                    >
                        <i className="fa fa-envelope" aria-hidden="true"></i>
                        AWITECK@PRINCETON.EDU
                    </a>
                </div>
            </section>
        );
    }
}

export default Contact;
