import React, { Component } from "react";
import $ from "jquery";
import "./App.scss";
import Header from "./components/Header";
import Footer from "./components/Footer";
import About from "./components/About";
import Experience from "./components/Experience";
import Projects from "./components/Projects";
import Skills from "./components/Skills";
import Contact from "./components/Contact";

class App extends Component {
    constructor(props) {
        super();
        this.state = {
            foo: "bar",
            resumeData: {},
            sharedData: {},
        };
    }

    componentDidMount() {
        this.loadSharedData();
    }

    loadResumeFromPath(path) {
        $.ajax({
            url: path,
            dataType: "json",
            cache: false,
            success: function (data) {
                this.setState({ resumeData: data });
            }.bind(this),
            error: function (xhr, status, err) {
                alert(err);
            },
        });
    }

    loadPortfolioFromPath(path) {
        $.ajax({
            url: path,
            dataType: "json",
            cache: false,
            success: function (data) {
                this.setState({ sharedData: data });
                document.title = `${this.state.sharedData.basic_info.name}`;
            }.bind(this),
            error: function (xhr, status, err) {
                alert(err);
            },
        });
    }

    loadSharedData() {
        this.loadPortfolioFromPath(`portfolio_shared_data.json`);
        this.loadResumeFromPath(`resume.json`);
    }

    render() {
        return (
            <div>
                <Header sharedData={this.state.sharedData.basic_info} />
                <About
                    resumeBasicInfo={this.state.resumeData.basic_info}
                    sharedBasicInfo={this.state.sharedData.basic_info}
                />
                <Projects
                    resumeProjects={this.state.resumeData.projects}
                    resumeBasicInfo={this.state.resumeData.basic_info}
                />
                <Skills
                    sharedSkills={this.state.sharedData.skills}
                    resumeBasicInfo={this.state.resumeData.basic_info}
                />
                <Experience
                    resumeExperience={this.state.resumeData.experience}
                    resumeBasicInfo={this.state.resumeData.basic_info}
                />
                <Contact sharedBasicInfo={this.state.sharedData.basic_info} />
                <Footer sharedBasicInfo={this.state.sharedData.basic_info} />
            </div>
        );
    }
}

export default App;
