import React, { Component } from "react";

class BlinkingText extends Component {
    constructor(props) {
        super(props);
        this.state = {
            opacity: 1,
            position: { top: 0, left: 0 },
        };
    }

    randomizeEffect = () => {
        const flickerPos = 5;
        const randomTop = Math.floor(Math.random() * 5) - flickerPos; // Jumps between -flickerPos and flickerPos
        const randomLeft = Math.floor(Math.random() * 5) - flickerPos;

        this.setState({
            opacity: Math.random(),
            position: { top: randomTop, left: randomLeft },
        });
    };

    componentDidMount() {
        this.flicker = setInterval(
            this.randomizeEffect,
            Math.floor(Math.random() * 200) + 50
        );
    }

    componentWillUnmount() {
        clearInterval(this.flicker);
    }

    render() {
        const textStyle = {
            opacity: this.state.opacity,
            fontSize: "64px",
            position: "relative",
            top: this.state.position.top,
            left: this.state.position.left,
        };

        return <h1 style={textStyle}>{this.props.text}</h1>;
    }
}

export default BlinkingText;
